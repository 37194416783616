import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import AppPageContainer from '../../../components/AppPageContainer';
import BasicModal from '../../../components/BasicModal';
import CreateQuoteForm from '../../../components/create-quote-form';
import ToolBar from '../../../components/ToolBar';
import { Keys } from '../../../modules/keys_module';
import { Quotes, fetchQuotes } from '../../../modules/offsets_module';
import { Box, Button, Tag } from '@chakra-ui/react';


const OffsetsPage = () => {
    const quotes = Quotes.use();
    const keys = Keys.use();

    const quoteRefs = useRef(new Map())
    console.log(quoteRefs);
    const [createQuoteModal, setCreateQuoteModal] = useState(false);
 
    useEffect(() => {
        fetchQuotes(keys.activeKey)
    }, [keys.activeKey, keys.activeEnv])

    const afterCreate = (uid) => {
        setCreateQuoteModal(false)
        quoteRefs.current[uid].scrollIntoView({ behaivior: 'smooth', block: 'start' })
    }

    return (
        <AppPageContainer activePage="offsets">
            <BasicModal close={() => setCreateQuoteModal(false)} isOpen={createQuoteModal} title="Quick Quote">
                <CreateQuoteForm afterSubmit={afterCreate} currentKey={keys.activeKey} />
            </BasicModal>
            <ToolBar pageName="Your Offsets" />
            { (quotes?.additionalInfo && quotes.error) && (
                <div className="mt-4 p-3 border-error border rounded-lg bg-error bg-opacity-20">
                    Your currently selected API environment does not have an active API key.
                </div>
            )}
            <Box my={4} p={2} className=" bg-gray-100 border border-gray-300 rounded-md">
                <Button colorScheme="green" onClick={() => setCreateQuoteModal(true)} >Create Quote</Button>
            </Box>
            { (!quotes?.loading && quotes?.quotes) && (
                <div className="">
                    {quotes.quotes?.map(o => (
                        <div ref={(element) => quoteRefs.current[o.Uid] = element} className="mt-4 p-3 border border-gray-200 rounded-lg w-full shadow-md" key={o.Uid}>
                            <div className="space-x-2 flex items-center">
                                {o.Classification && (<Tag>{o.Classification}</Tag>)}
                                <span className="text-xs text-primary-dark">{o.Uid}</span>
                            </div>
                            <div className="flex mt-3">
                                <div className="mr-4">
                                    <div className="text-xs">Total Impact</div>
                                    <div className="">{o.carbon_impact}kg CO2</div>
                                </div>
                                <div>
                                    <div className="text-xs">Total Cost</div>
                                    <div className="">${o.Cost / 100}</div>
                                </div>
                            </div>
                            <hr className="my-2" />
                            {o.Offsets.map(off => (
                                <div key={off.Uid} className="space-x-2 flex items-center justify-between">
                                    <div className="flex space-x-2">
                                        <Tag colorScheme="green">{off.Status}</Tag>
                                        <Tag colorScheme="gray">{off.Type}</Tag>
                                    </div>
                                    <div className="leading-2">{off.Provider.name}</div>
                                    <div>{off.Ratio * 100}%</div>
                                    <div>${off.Provider.cost_per_ton / 100} per ton</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </AppPageContainer>
    );
};

export default OffsetsPage;

